import Airtable from 'airtable';
import moment from 'moment';

/**
 * Production: https://airtable.com/tbl8MaxABG0KBJwTD/viwgXLXBbx288vaTv?blocks=hide
 * Staging: https://airtable.com/tblEqkcTPAPmXJ9Ef/viwMBVCUprRKuvNE7?blocks=hide
 */

/**
 * Production: https://airtable.com/tbldmNVtE7eGH8LFh/viwVRlL9FPeosNZlf?blocks=hide
 * Staging: https://airtable.com/tblI4jNQLIK1L5mUC/viwIzERCN02TBv8rO?blocks=hide
 */

/* NEW WAY TO CONNECT WITH AIRTABLE */
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

const sessions = Airtable.base(process.env.REACT_APP_AIRTABLE_SESSIONS_BASE);
const featureFlags = Airtable.base(process.env.REACT_APP_AIRTABLE_FEATURES_BASE);

/**
 * Retrieve the access control list of users who can access the sessions page.
 */
export const getSessionsACL = callback => {
  featureFlags('Sessions')
    .select({
      maxRecords: 100,
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        const data = records.map(r => r.fields.access);
        callback(data);
        fetchNextPage();
      },
      async function done(err) {}
    );
};

const mapRecordToSession = record => {
  return {
    ...record.fields,
    oid: record.id,
  };
};

export const getDateToSessions = callback => {
  sessions('Sessions')
    .select({
      maxRecords: 100,
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        const data = records.map(mapRecordToSession);
        const dateToSessions = {};

        data.forEach(session => {
          if (!session.enabled) {
            return;
          }

          const dt = moment(session.start_datetime);
          const day = dt.format('dddd');
          const ordinal = dt.localeData().ordinal(dt.date());
          const date = `${day} ${ordinal}`;
          const sessions = dateToSessions[date] ? dateToSessions[date] : [];
          dateToSessions[date] = [...sessions, session];
        });

        callback(dateToSessions);
        fetchNextPage();
      },
      async function done(err) {}
    );
};

export const getDateToSessionsByMonth = callback => {
  const cleanup = () => {};

  const fetchSessions = async () => {
    sessions('Sessions')
      .select({
        maxRecords: 100,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          const data = records
            .map(mapRecordToSession)
            .sort((a, b) => moment(a.start_datetime).diff(moment(b.start_datetime)));

          const categorizedSessions = {
            past: [],
            current: {},
            upcoming: {},
          };

          const now = moment(); // Current moment
          const today = moment().startOf('day');
          const nextMonthStart = moment()
            .add(1, 'months')
            .startOf('month');

          data.forEach(session => {
            if (!session.enabled) {
              return;
            }

            const start = moment(session.start_datetime);
            const end = moment(session.end_datetime);
            const monthYear = start.format('MMMM YYYY');

            if (end.isBefore(now)) {
              // Sessions that have ended are considered past
              categorizedSessions.past.push(session);
            } else if (start.isSameOrAfter(today) && start.isBefore(nextMonthStart)) {
              // Sessions from today to the end of the current month, grouped by month-year
              if (!categorizedSessions.current[monthYear]) {
                categorizedSessions.current[monthYear] = [];
              }
              categorizedSessions.current[monthYear].push(session);
            } else if (start.isSameOrAfter(nextMonthStart)) {
              // Sessions from next month onward, grouped by month-year
              if (!categorizedSessions.upcoming[monthYear]) {
                categorizedSessions.upcoming[monthYear] = [];
              }
              categorizedSessions.upcoming[monthYear].push(session);
            }
          });

          callback(categorizedSessions);
          fetchNextPage();
        },
        async function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  fetchSessions();

  return cleanup;
};
export const getSession = (oid, callback) => {
  sessions('Sessions').find(oid, function(err, record) {
    if (err) {
      console.error(err);
      return;
    }

    callback(mapRecordToSession(record));
  });
};

export const getSignupByEmail = (email, callback) => {
  sessions('Signups')
    .select({
      maxRecords: 1,
      view: 'Grid view',
      filterByFormula: `{email} = "${email}"`,
    })
    .eachPage(
      function page(records) {
        if (records && records[0]) {
          const signup = {
            id: records[0].id,
            sessions: records[0].fields.Session ? records[0].fields.Session : [],
          };
          callback(signup);
        } else {
          callback({ sessions: [] });
        }
      },
      async function done(err) {}
    );
};

export const signupToSession = (signupId, email, existingSessions, sessionOid, callback) => {
  const newSessions = existingSessions ? [...existingSessions, sessionOid] : [sessionOid];

  const payload = [
    {
      fields: {
        email: email,
        Session: newSessions,
      },
    },
  ];

  if (signupId) {
    payload[0]['id'] = signupId;
    sessions('Signups').update(payload, (err, records) => {
      if (err) {
        console.error(err);
      } else {
        callback(records);
      }
    });
  } else {
    sessions('Signups').create(payload, (err, records) => {
      if (err) {
        console.error(err);
      } else {
        callback(records);
      }
    });
  }
};

export const getEventsByMonth = callback => {
  sessions('oogoEvents')
    .select({
      maxRecords: 100,
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        const data = records
          .map(mapRecordToSession)
          .sort((a, b) => moment(a.start_datetime).diff(moment(b.start_datetime)));

        const categorizedSessions = {
          past: [],
          upcoming: {},
        };

        const now = moment(); // Current moment
        const today = moment().startOf('day');
        data.forEach(session => {
          if (!session.enabled) {
            return;
          }

          const start = moment(session.start_datetime);
          const end = moment(session.end_datetime);
          const monthYear = start.format('MMMM YYYY');

          if (end.isBefore(now)) {
            // Sessions that have ended are considered past
            categorizedSessions.past.push(session);
          } else if (start.isSameOrAfter(today) /* && start.isBefore(nextMonthStart) */) {
            // Sessions from today to the end of the current month, grouped by month-year
            if (!categorizedSessions.upcoming[monthYear]) {
              categorizedSessions.upcoming[monthYear] = [];
            }
            categorizedSessions.upcoming[monthYear].push(session);
          }
        });

        callback(categorizedSessions);
        fetchNextPage();
      },
      async function done(err) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
};

export const getOogoEvent = (oid, callback) => {
  sessions('oogoEvents').find(oid, function(err, record) {
    if (err) {
      console.error(err);
      return;
    }

    callback(mapRecordToSession(record));
  });
};

export const getSignupOogoEventByEmail = (email, callback) => {
  sessions('Signups')
    .select({
      maxRecords: 1,
      view: 'Grid view',
      filterByFormula: `{email} = "${email}"`,
    })
    .eachPage(
      function page(records) {
        if (records && records[0]) {
          const signup = {
            id: records[0].id,
            oogoEvents: records[0].fields.oogoEvent ? records[0].fields.oogoEvent : [],
          };
          callback(signup);
        } else {
          callback({ oogoEvents: [] });
        }
      },
      async function done(err) {}
    );
};

export const signupToOogoEvent = (signupId, email, existingSessions, sessionOid, callback) => {
  const newSessions = existingSessions ? [...existingSessions, sessionOid] : [sessionOid];

  const payload = [
    {
      fields: {
        email: email,
        oogoEvent: newSessions,
      },
    },
  ];

  if (signupId) {
    payload[0]['id'] = signupId;
    sessions('Signups').update(payload, (err, records) => {
      if (err) {
        console.error(err);
      } else {
        callback(records);
      }
    });
  } else {
    sessions('Signups').create(payload, (err, records) => {
      if (err) {
        console.error(err);
      } else {
        callback(records);
      }
    });
  }
};

export const getAllOogoBoardData = callback => {
  sessions('oogoBoard')
    .select({
      maxRecords: 100,
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        const data = records.map(mapRecordToSession);

        // Directly pass the data array to the callback
        callback(data);
        fetchNextPage();
      },
      async function done(err) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
};

export const getOogoBoardEntry = (oid, callback, errorCallback) => {
  sessions('oogoBoard').find(oid, function(err, record) {
    if (err) {
      console.error(err);
      errorCallback(err);
      return;
    }

    callback(mapRecordToSession(record));
  });
};
